import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home/Home';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path='/' element={<h1>Welcome to MMHD Service API</h1>}/> */}
          {/* <Route path='/service_api' element={<Home/>}/> */}
          <Route path='/' element={<Home/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
