import React, { useEffect, useState } from 'react';
import './Home.scss';
import { Button, Image } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import mmhd_logo from '../../images/mmhdWhiteT.png'
import Onyx from "../../images/Onyx.svg";
import { handleLogout, handleRedirect } from '../../functions/Home.func';

const Home = () => {
    let navigate = useNavigate()
  const { search } = useLocation()

  const [redirectUrl, setRedirectUrl] = useState("")

  const authToken = new URLSearchParams(search).get("access_token")
  const parent = new URLSearchParams(search).get("parent")
  const redirectUrl1 = new URLSearchParams(search).get("redirectUrl")

  const [remainingSeconds, setRemainingSeconds] = useState(15)
  let show2=true
  useEffect(() => {
    setRedirectUrl(redirectUrl1===null? "unauthorized" : redirectUrl1)

    let intervalId: any = 0
    if (show2) {
      intervalId = setInterval(() => {
        setRemainingSeconds((prevState: number) => (prevState -= 1))
        if (remainingSeconds === 1) {
          if(redirectUrl === "unauthorized" || "" || null){
            handleLogout(navigate)
          }
          else{
            window.location.href = `${redirectUrl}?access_token=${authToken}`
          }
        }
      }, 1000)
    } else if (!show2 && remainingSeconds !== 0 && intervalId !== null) {
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId)
  }, [remainingSeconds, show2,authToken,redirectUrl])

  
  return (
        <div className='servicepage-body'>
          <div className='service_mmhd_logo'>
            <Image src={mmhd_logo} alt='mmhdlogo' className='mmhd_logo_dashboard' />

            <div className='onyx_logo_landing2'>
              <p className='power_by' data-testid='MenuBarMobiled_by'>Powered By :</p>
              <Image src={Onyx} alt='Onyx_Logo' className='onyx_logo_landing' data-testid='onyx_logo' />
            </div>

          </div>
          <div className='servicepage-content-wrapper'>
            <h1>MoveMyHealthData APIs as Service</h1>
            <br/>
              {
                window.location.search === "" ? 
            <section>
                <>
                  <h5>Please provide parameters</h5>
                </>
              
            </section>
            :
            <section>
                  <p><b>Parent Oranization</b> : {parent}</p>
                  <p><b>Redirect URL</b> : {redirectUrl}</p>
                  {redirectUrl === "unauthorized"  || null || ""? 
                  <p>Your Redirect URL is not whitelisted in MMHD App. 
                    <br/>
                    You will be automatically logged out in{" "}
                  {remainingSeconds.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                  })}{" "}
                  seconds. To Logout immediately, click the Exit button below.</p>
                :
                <p>Success!!!. Now you'll get redirected to {parent}'s page in {remainingSeconds.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}{" "}
                seconds. <br/>To redirect immediately, click the redirect button below..</p>}
                <Button 
                variant='success' 
                disabled={redirectUrl === "unauthorized" || null || "" ? true : false} 
                onClick={()=>{handleRedirect(redirectUrl,authToken)}}
                data-testid='redirect-btn'>
                  Redirect to {parent}'s page 
                {redirectUrl !== "unauthorized"  || null || "" || undefined? 
                <span>({remainingSeconds.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                  })}{" "})</span>
                  :""}
                </Button>
                <Button 
                variant='secondary' 
                style={{'marginLeft' : '1rem'}} 
                onClick={()=>{handleLogout(navigate)}}
                data-testid='exit-btn'>
                  Exit {redirectUrl === "unauthorized"  || null || ""? 
                <span>({remainingSeconds.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                  })}{" "})</span>
                  :""}</Button>
                </section>
          }
          </div>
    </div>
  )
}

export default Home