//@ts-ignore
export const handleRedirect = (redirectUrl,authToken) =>{
    window.location.href = `${redirectUrl}?access_token=${authToken}`
  }

  //@ts-ignore
export const handleLogout = (navigate) => {
    sessionStorage.clear()
    localStorage.clear()
    navigate('/')
  }
